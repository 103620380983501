<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="800"
  >
    <v-card :loading="isLoading">
      <v-card-title class="caption">
        <h3>{{$_strings.order.CALCULATE_COST}}</h3>
        <v-row>
          <v-col class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row v-for="(mainCost, index) in itemsMainCost" :key="index">
          <v-col cols="12" sm="8">
            <h2 class="caption">{{typeCost(index, mainCost.costName)}}</h2>
            <div class="d-flex">
              <v-text-field
                outlined
                dense
                disabled
                v-model="mainCost.quantity"
                hide-details
              ></v-text-field>
              <v-select
                v-if="mainCost.costName && mainCost.costName !== 'MULTIPICK' && mainCost.costName !== 'MULTIDROP'"
                v-model="mainCost.costName"
                :items="itemsTypeCost"
                hide-details
                dense
                outlined
                disabled
              >
              </v-select>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <h2
              v-if="typeCost(index, mainCost.costName)"
              class="caption"
            >
              {{$_strings.order.AMOUNT_OF_COSTS}}
            </h2>
            <common-text-field-currency
              dense
              outlined
              v-model="form.totalPrice"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          class="white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          color="primary"
          @click="save"
          :disabled="isLoading || !form.totalPrice"
        >
          {{$_strings.common.SAVE}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { convertTextTypeCost } from '@/helper/commonHelpers';

export default {
  data() {
    return {
      isLoading: false,
      dialog: false,
      form: {
        totalPrice: 0,
      },
      orderSelected: {},
      itemsMainCost: [],
      itemsTypeCost: [],
    };
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.form = {
          totalPrice: 0,
        };
        return;
      }
      this.fetchData();
    },
  },
  methods: {
    typeCost(index, type) {
      if (type.toLowerCase() === 'multipick' || type.toLowerCase() === 'multidrop') return `${type} (Rp)`;
      if (index > 0) return null;
      return this.$_strings.order.MAIN_COST;
    },
    async fetchData() {
      try {
        const { shipmentId } = this.orderSelected;
        this.isLoading = true;
        const resultMainCost = await this.$_services.order.getMainCost(shipmentId);
        if (resultMainCost.data.mainCost) {
          this.itemsMainCost = resultMainCost.data.mainCost;
          this.itemsTypeCost = resultMainCost.data.mainCost.map((cost) => ({
            value: cost.costName,
            text: convertTextTypeCost(cost.costName),
          }));
        }
      } finally {
        this.isLoading = false;
      }
    },
    async save() {
      const { shipmentId } = this.orderSelected;
      try {
        this.isLoading = true;
        const data = [];
        let isValid = true;
        this.form.cost.forEach((cost) => {
          if (!(cost.costName) || !(cost.totalPrice)) {
            isValid = false;
          }
          data.push(cost);
        });
        if (!isValid) {
          this.$dialog.notify.error('Mohon periksa field yang belum diisi');
          return;
        }
        await this.$_services.order.saveCost(shipmentId, this.form);
        this.$dialog.notify.success('Berhasil');
        this.dialog = false;
        this.$emit('fetchShipmentDetail');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
