var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"calculate-widths":"","item-key":"limitId","show-expand":"","headers":_vm.displayedHeader,"items":_vm.items,"expanded":_vm.expanded,"server-items-length":_vm.totalData,"options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page},"loading":_vm.isLoading,"loading-text":_vm.$_strings.order.LOADING_TEXT},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.pagination=$event},"item-expanded":function (ref) {
	var value = ref.value;
	var item = ref.item;

	return value && _vm.fetchSubInvoice(item);
}},scopedSlots:_vm._u([{key:"item.invoiceNo",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.invoiceNo || '-'))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatAsCurrency(item.price)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.subLoading)?[_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-progress-circular',{staticClass:"mx-auto",attrs:{"color":"primary","size":"30","indeterminate":""}})],1)],1)],1)]:(item.subInvoice)?[_c('td',{staticClass:"white",attrs:{"colspan":headers.length}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-sheet',{staticClass:"pa-2 rounded",attrs:{"outlined":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-1"},[_c('v-data-table',{staticClass:"grey lighten-4",attrs:{"calculate-widths":"","hide-default-footer":"","headers":_vm.headerSubs,"items":item.subInvoice},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function (){ return _vm.showDialogCalculateCost(item); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.EDIT))])])]}}],null,true)})],1)],1)],1)],1)],1)]:_vm._e()]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.invoice.INVOICE_LIST)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1),_c('CalculateCost',{ref:"calculateCost"}),_c('CalculateMainCost',{ref:"calculateMainCost"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }