<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="800"
  >
    <v-card :loading="isLoading">
      <v-card-title class="caption">
        <h3>{{$_strings.order.CALCULATE_COST}}</h3>
        <v-row>
          <v-col class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <MainCost
          :key="key"
          :shipmentId="orderSelected.shipmentId"
          :form="form"
        />
        <TableListCost
          :key="key + 1"
          :shipmentId="orderSelected.shipmentId"
          :form="form"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          class="white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          color="primary"
          @click="save"
          :disabled="isLoading || !form.cost.length"
        >
          {{$_strings.common.SAVE}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MainCost from './MainCost';
import TableListCost from './TableListCost';

export default {
  components: {
    MainCost,
    TableListCost,
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      key: Math.random(),
      form: {
        totalPrice: 0,
        cost: [],
      },
      orderSelected: {},
      shipmentOrder: 1,
    };
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.form = {
          totalPrice: 0,
          cost: [],
        };
        return;
      }
      this.key += 1;
    },
  },
  methods: {
    async save() {
      const { shipmentId } = this.orderSelected;
      try {
        this.isLoading = true;
        const data = [];
        let isValid = true;
        this.form.cost.forEach((cost) => {
          if (!(cost.costName) || !(cost.totalPrice)) {
            isValid = false;
          }
          data.push(cost);
        });
        if (!isValid) {
          this.$dialog.notify.error('Mohon periksa field yang belum diisi');
          return;
        }
        await this.$_services.order.saveCost(shipmentId, this.form);
        this.$dialog.notify.success('Berhasil');
        this.dialog = false;
        this.$emit('fetchShipmentDetail');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
