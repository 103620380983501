<template>
  <v-row>
    <v-col>
      <p class="caption ma-0 ml-4">
        {{$_strings.invoice.INVOICE_NO}} : {{invoiceNo ? invoiceNo : '-'}}
      </p>
      <p class="caption ma-0 ml-4">
        {{$_strings.invoice.ORDER_PERIODE}} : {{invoicePeriod.invoiceGroup}} {{invoicePeriod.startDate }} s/d {{invoicePeriod.endDate}}
      </p>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      class="d-flex justify-end"
    >
      <v-btn
        class="mr-2"
        color="primary"
        small
        @click="$emit('updateInvoice')"
      >
        {{$_strings.invoice.TITLE_EDIT}}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    invoiceNo: {
      type: String,
      default: '',
    },
    invoicePeriod: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
