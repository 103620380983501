<template>
  <v-container fluid>
    <v-sheet class="mt-2" rounded>
      <InvoiceHeader
        :invoiceNo="invoiceNo"
        :invoicePeriod="invoicePeriod"
        @updateInvoice="updateInvoice"
      />
      <TableList
        :items="items"
        @setData="setData"
      />
    </v-sheet>
  </v-container>
</template>

<script>

import {
  renamePeriode,
  getDate,
} from '@/helper/commonHelpers';
import InvoiceHeader from './InvoiceHeader';
import TableList from './TableList';

export default {
  name: 'fixed-price-customer-cancel-detail',
  components: {
    InvoiceHeader,
    TableList,
  },
  data() {
    return {
      items: [],
      invoiceNo: '',
      invoicePeriod: {
        endDate: '',
        invoiceGroup: '',
        startDate: '',
      },
    };
  },
  methods: {
    renamePeriode,
    getDate,
    setData(result) {
      if (result.data) {
        this.items = result.data.limitList.contents;
        this.invoiceNo = result.data.invoiceNo;
        this.invoicePeriod.invoiceGroup = this.renamePeriode(result.data.invoicePeriod.invoiceGroup);
        this.invoicePeriod.startDate = this.getDate(result.data.invoicePeriod.startDate);
        this.invoicePeriod.endDate = this.getDate(result.data.invoicePeriod.endDate);
      }
    },
    async updateInvoice() {
      const form = this.items.map((item) => ({
        invoiceId: item.invoiceId,
        invoiceFixedPriceDetailId: item.invoiceFixedPriceDetailId,
        priceUnit: item.price,
      }));
      const { invoiceId } = this.$route.params;
      try {
        this.$root.$loading.show();
        await this.$_services.invoiceFixedPriceCustomerCancel.updateInvoice(invoiceId);
        this.$dialog.notify.success(this.$_strings.invoice.EDIT_MESSAGE_TEXT);
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
