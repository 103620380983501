<template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          key="id"
          hide-default-footer
          calculate-widths
          :loading="isLoading"
          :headers="headers"
          :items="form.cost"
        >
          <template v-slot:[`item.costName`]={item,index}>
            <common-auto-complete-items
              type="additionalCost"
              searchName="name"
              item-value="id"
              item-text="reason"
              v-model="item.id"
              dense
              outlined
              hide-details
              class="body-2"
              clearable
              :filter="costFilterItems"
              :items="itemsCost"
              @updateItems="updateCostListItems"
              @change="changeCostName(index, item)"
            />
          </template>
          <template v-slot:[`item.totalPrice`]={item}>
            <common-text-field-currency
              dense
              outlined
              v-model="item.totalPrice"
              hide-details
            />
          </template>
          <template v-slot:[`item.costDescription`]={item}>
            <v-text-field
              dense
              outlined
              v-model="item.costDescription"
              hide-details
            />
          </template>
          <template v-slot:[`item.action`]={item,index}>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  icon
                  color="red"
                  @click="() => removeRowTable(index)"
                  @click.stop.prevent
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.common.DELETE}}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="mt-2">
          <v-btn
            @click="addRowTable"
            :disabled="isLoading"
            class="pa-0"
            text>
            <v-icon color="primary">mdi-plus-circle</v-icon>
            <span class="primary--text">Tambah Baris</span>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row class="grey lighten-5">
      <v-col class="pb-0" cols="12" sm="8">
        <p class="title mb-0">{{$_strings.order.TOTAL_COST}}</p>
      </v-col>
      <v-col cols="12" sm="4">
        <common-text-field-currency
          dense
          outlined
          disabled
          hide-details
          v-model="totalCost"
        />
      </v-col>
    </v-row>
    <v-divider class="mb-2 mt-2"></v-divider>
    <v-row>
      <v-col class="pb-0" cols="12" sm="8">
        <p class="title mb-0">{{$_strings.order.TOTAL_BILL}}</p>
      </v-col>
      <v-col cols="12" sm="4">
        <common-text-field-currency
          dense
          outlined
          disabled
          hide-details
          v-model="totalCost"
        />
        <p class="caption text-end">(Belum termasuk biaya Pengiriman)</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    shipmentId: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.fetchData();
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: this.$_strings.order.ADDITIONAL_COST,
          value: 'costName',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.AMOUNT_OF_COSTS,
          value: 'totalPrice',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.order.DESCRIPTION,
          value: 'costDescription',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.common.ACTION,
          value: 'action',
          class: 'white--text primary',
          sortable: false,
        },
      ],
      items: [],
      itemsCost: [],
      costFilterItems: {
        search: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
    };
  },
  computed: {
    totalCost: {
      get() {
        return this.form.cost.reduce((curr, item) => curr + item.totalPrice, 0) + this.form.totalPrice;
      },
      set() {},
    },
  },
  methods: {
    updateCostListItems(items) {
      this.itemsCost = [...this.itemsCost, ...items];
    },
    addRowTable() {
      let id = this.items.length;
      const item = [...this.form.cost].pop();
      if (item) id = (item.id || 0) + Math.random();
      this.form.cost.push({
        id,
        costName: '',
        isTaxed: '',
        totalPrice: 0,
        quantity: '',
        costDescription: '',
        costType: '',
      });
    },
    removeRowTable(index) {
      this.form.cost.splice(index, 1);
    },
    changeCostName(index, item) {
      if (item.id) {
        const getCost = this.itemsCost.find((cost) => cost.id === item.id);
        this.form.cost[index].costName = getCost.reason;
        this.form.cost[index].isTaxed = getCost.needConfirm;
        this.form.cost[index].quantity = 1;
        this.form.cost[index].costType = getCost.type;
      }
    },
    async fetchData() {
      try {
        this.isLoading = true;
        const result = await this.$_services.order.getUnitCost(this.shipmentId);
        result.data.forEach((item) => {
          this.form.cost = item.cost.map((c) => ({
            ...c,
          }));
          this.itemsCost = item.cost.map((c) => ({
            id: c.id,
            reason: c.costName,
          }));
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
