<template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          calculate-widths
          item-key="limitId"
          show-expand
          :headers="displayedHeader"
          :items="items"
          :expanded.sync="expanded"
          :server-items-length="totalData"
          :options.sync="pagination"
          :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
          :loading="isLoading"
          :loading-text="$_strings.order.LOADING_TEXT"
          @item-expanded="({value, item}) => value && fetchSubInvoice(item)"
        >
          <template v-slot:[`item.invoiceNo`]={item}>
            <p class="ma-0">{{ item.invoiceNo || '-' }}</p>
          </template>
          <template v-slot:[`item.price`]={item}>
            <span>{{ formatAsCurrency(item.price) }}</span>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <template v-if="item.subLoading">
              <td :colspan="headers.length">
                <v-row justify="center">
                  <v-col cols="auto">
                    <v-progress-circular
                      class="mx-auto"
                      color="primary"
                      size="30"
                      indeterminate
                    ></v-progress-circular>
                  </v-col>
                </v-row>
              </td>
            </template>
            <template v-else-if="item.subInvoice">
              <td class="white" :colspan="headers.length">
                <v-container fluid>
                  <v-sheet outlined class="pa-2 rounded">
                    <v-row>
                      <v-col class="pa-1">
                        <v-data-table
                          calculate-widths
                          class="grey lighten-4"
                          hide-default-footer
                          :headers="headerSubs"
                          :items="item.subInvoice"
                        >
                          <template v-slot:[`item.action`]={item}>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  x-small
                                  icon
                                  class="mr-2"
                                  color="primary"
                                  @click="()=> showDialogCalculateCost(item)"
                                >
                                  <v-icon> mdi-pencil</v-icon>
                                </v-btn>
                              </template>
                              <span>{{$_strings.common.EDIT}}</span>
                            </v-tooltip>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-container>
              </td>
            </template>
          </template>
          <template v-slot:[`footer.page-text`]="props">
            <span>
              {{$_strings.invoice.INVOICE_LIST}}
              <span v-if="items.length">
                {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
              </span>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <CalculateCost
      ref="calculateCost"
    />
    <CalculateMainCost
      ref="calculateMainCost"
    />
  </div>
</template>

<script>
import {
  handleSortBy,
  handlerPagination,
  defaultPagination,
  skipEmptyStringObject,
} from '@/helper/commonHelpers';
import CalculateCost from '../Dialog/CalculateCost';
import CalculateMainCost from '../Dialog/CalculateMainCost';

export default {
  components: {
    CalculateCost,
    CalculateMainCost,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: '',
          value: 'data-table-expand',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.INVOICE_NO,
          value: 'invoiceNo',
          class: 'white--text primary text-capitalize',
          width: '150px',
        },
        {
          text: this.$_strings.mou.TRANSPORT_TYPE,
          value: 'transportTypeName',
          class: 'white--text primary text-capitalize',
          width: '180px',
        },
        {
          text: this.$_strings.mou.NUMBER_OF_LIMIT,
          value: 'limitPerNopol',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.mou.NUMBER_OF_RITASE,
          value: 'ritaseUsage',
          class: 'white--text primary text-capitalize',
          width: '150px',
        },
        {
          text: this.$_strings.mou.LICENSE_PLATE,
          value: 'vehicleNo',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.mou.PRICE,
          value: 'price',
          class: 'white--text primary text-capitalize',
          width: '150px',
        },
      ],
      headerSubs: [
        {
          text: this.$_strings.invoice.ORDER_NO,
          value: 'shipmentTitle',
          class: 'black--text amber accent-3',
          width: '150px',
          sortable: false,
        },
        {
          text: this.$_strings.order.DRIVER_NAME,
          value: 'driverName',
          class: 'black--text amber accent-3',
          width: '150px',
          sortable: false,
        },
        {
          text: this.$_strings.order.ORIGIN,
          value: 'originLocation',
          class: 'black--text amber accent-3',
          width: '180px',
          sortable: false,
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'destinationLocation',
          class: 'black--text amber accent-3',
          width: '200px',
          sortable: false,
        },
        {
          text: this.$_strings.common.ACTION,
          value: 'action',
          class: 'black--text amber accent-3',
          width: '150px',
          sortable: false,
        },
      ],
      totalData: 0,
      pagination: defaultPagination(),
      expanded: [],
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        this.fetchData();
      },
      deep: true,
    },
  },
  computed: {
    displayedHeader() {
      const { canUpdate } = this.userAccess;
      return this.headers.filter((header) => {
        if (!canUpdate) {
          return header.value !== 'action';
        }
        return header;
      });
    },
  },
  methods: {
    updateFixedInvoice() {},
    showDialogCalculateCost(item) {
      this.$refs.calculateCost.orderSelected = {
        shipmentId: item.shipmentId,
      };
      this.$refs.calculateCost.dialog = true;
    },
    formatAsCurrency(value) {
      const formatNumber = Intl.NumberFormat('id-ID');
      return `Rp. ${formatNumber.format(value) || 0}`;
    },
    async fetchData() {
      const {
        page,
        itemsPerPage: size,
        sortBy,
        sortDesc,
      } = this.pagination;
      const { invoiceId } = this.$route.params;
      const filters = skipEmptyStringObject({
        page: page - 1,
        size,
        sort: handleSortBy({ sortBy, sortDesc }),
      });
      this.expanded = [];
      try {
        this.isLoading = true;
        const result = await this.$_services.invoiceFixedPriceCustomerCancel.fetchListDetail(invoiceId, filters);
        this.$emit('setData', result);
        if (result.data) this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    async fetchSubInvoice(invoice) {
      if (invoice.subInvoice) return;
      invoice.subLoading = true;
      const companyType = 'shipper';
      try {
        const result = await this.$_services.invoice.listFixedPriceLimitInvoice(invoice.invoiceId, invoice.limitId, companyType);
        const contents = result.data.contents.map((i) => ({ ...i }));
        this.$set(invoice, 'subInvoice', contents);
      } finally {
        invoice.subLoading = false;
      }
    },
  },
};
</script>
