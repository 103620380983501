var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{key:"id",attrs:{"hide-default-footer":"","calculate-widths":"","loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.form.cost},scopedSlots:_vm._u([{key:"item.costName",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('common-auto-complete-items',{staticClass:"body-2",attrs:{"type":"additionalCost","searchName":"name","item-value":"id","item-text":"reason","dense":"","outlined":"","hide-details":"","clearable":"","filter":_vm.costFilterItems,"items":_vm.itemsCost},on:{"updateItems":_vm.updateCostListItems,"change":function($event){return _vm.changeCostName(index, item)}},model:{value:(item.id),callback:function ($$v) {_vm.$set(item, "id", $$v)},expression:"item.id"}})]}},{key:"item.totalPrice",fn:function(ref){
var item = ref.item;
return [_c('common-text-field-currency',{attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(item.totalPrice),callback:function ($$v) {_vm.$set(item, "totalPrice", $$v)},expression:"item.totalPrice"}})]}},{key:"item.costDescription",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(item.costDescription),callback:function ($$v) {_vm.$set(item, "costDescription", $$v)},expression:"item.costDescription"}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":"","color":"red"},on:{"click":[function () { return _vm.removeRowTable(index); },function($event){$event.stopPropagation();$event.preventDefault();}]}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.DELETE))])])]}}],null,true)}),_c('div',{staticClass:"mt-2"},[_c('v-btn',{staticClass:"pa-0",attrs:{"disabled":_vm.isLoading,"text":""},on:{"click":_vm.addRowTable}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus-circle")]),_c('span',{staticClass:"primary--text"},[_vm._v("Tambah Baris")])],1)],1)],1)],1),_c('v-row',{staticClass:"grey lighten-5"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"8"}},[_c('p',{staticClass:"title mb-0"},[_vm._v(_vm._s(_vm.$_strings.order.TOTAL_COST))])]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('common-text-field-currency',{attrs:{"dense":"","outlined":"","disabled":"","hide-details":""},model:{value:(_vm.totalCost),callback:function ($$v) {_vm.totalCost=$$v},expression:"totalCost"}})],1)],1),_c('v-divider',{staticClass:"mb-2 mt-2"}),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"8"}},[_c('p',{staticClass:"title mb-0"},[_vm._v(_vm._s(_vm.$_strings.order.TOTAL_BILL))])]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('common-text-field-currency',{attrs:{"dense":"","outlined":"","disabled":"","hide-details":""},model:{value:(_vm.totalCost),callback:function ($$v) {_vm.totalCost=$$v},expression:"totalCost"}}),_c('p',{staticClass:"caption text-end"},[_vm._v("(Belum termasuk biaya Pengiriman)")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }